import React, { useState, useEffect, useCallback, useMemo } from "react";
import CommonHeader from "../Common/CommonHeader";
import { useParams, useSearchParams } from "react-router-dom";
import CommonContainer from "../Common/CommonContainer";
import FailureModal from "../FailureModal";
import { useTestService } from "../../contexts/TestContext";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { TestTable } from "./TestTable";
import { TestRunTable } from "./TestRunTable";
import { InputData } from "./TestRunDetailModal";
import { useTeams } from "../../contexts/TeamContext";
import { Team } from "../../models/Team";
import { CommonWorkflowPortalSelector } from "../Common/CommonWorkflowPortalSelector";
import { useWorkflowConverter } from "../../contexts/WorkflowConverterContext";
import { useWorkflowService } from "../../contexts/WorkflowContext";

export const TestCenter: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const testService = useTestService();
  const teamService = useTeams();
  const workflowConverter = useWorkflowConverter();
  const workflowService = useWorkflowService();

  const [team, setTeam] = useState<Team>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [runTestState, setRunTestState] = useState<AnimationState>("ready");
  const [newTestState, setNewTestState] = useState<AnimationState>("ready");

  const inputData = useMemo<InputData | undefined>(() => {
    const type = searchParams.get("type") as "portal" | "workflow" | null;
    const id = searchParams.get("id");
    const versionIds = searchParams
      .get("versionIds")
      ?.split(",")
      .filter(Boolean);

    if (!type || !id) return undefined;

    return type === "portal"
      ? { type: "portal", itemId: id, versionIds }
      : { type: "workflow", itemId: id, versionIds };
  }, [searchParams]);

  const handleSelectedTests = useCallback((tests: string[]) => {
    setSelectedTests(tests);
  }, []);

  const handleNewTest = useCallback(async () => {
    if (!teamId || !inputData) return;

    setNewTestState("loading");

    const baseTest = {
      name: "New Test",
      parameterValues: {},
      createdAt: new Date(),
      messages: [],
    };

    try {
      let createdTest;
      const newSearchParams = new URLSearchParams(searchParams);

      if (inputData.type === "portal") {
        createdTest = await testService.testRepo.create(
          baseTest,
          testService.testPath(teamId, inputData.itemId!)
        );
        newSearchParams.set("portalTestId", createdTest.id!);
      } else if (inputData.type === "workflow") {
        createdTest = await testService.workflowTestRepo.create(
          baseTest,
          testService.workflowTestPath(teamId, inputData.itemId!)
        );
        newSearchParams.set("workflowTestId", createdTest.id!);
      }

      if (createdTest) {
        setSearchParams(newSearchParams);
        setNewTestState("success");
      }
    } catch (e) {
      setNewTestState("error");
      console.error("Failed to create test:", e);
    }
  }, [teamId, inputData, searchParams, testService, setSearchParams]);

  const handleRunTest = useCallback(async () => {
    if (!inputData || !teamId) return;

    setRunTestState("loading");

    if (inputData.type == "workflow") {
      const versionPromises = inputData.versionIds!.map((vId) =>
        workflowService.workflowVersionRepo.get(
          workflowService.workflowVersionPath(teamId!, inputData.itemId),
          vId
        )
      );
      const versions = await Promise.all(versionPromises);
      for (const version of versions) {
        if (!version) {
          setError("no version");
          setRunTestState("error");

          return;
        }
        const flow = JSON.parse(version?.uiDataString ?? "{}");
        const nodes = flow.nodes ?? [];
        const edges = flow.edges ?? [];
        const result = workflowConverter.getSteps(nodes, edges, team!, true);
        if (result.type == "issue") {
          setError(
            `Error in version ${version?.name}: ${result.problems[0].message}`
          );
          setRunTestState("error");
          return;
        } else {
          await workflowService.workflowVersionRepo.update(
            {
              demoFirstStepId: result.firstStepId,
              demoSteps: result.steps,
              demoStartNodeId: result.startNodeId,
            },
            workflowService.workflowVersionPath(teamId!, inputData.itemId),
            version!.id!
          );
        }
      }
    }

    if (error !== "") {
      return;
    }

    try {
      const testRunPromises = selectedTests.flatMap((testId) =>
        inputData.versionIds?.map((versionId) => {
          if (inputData.type === "portal") {
            return testService.runTest(
              testId,
              inputData.itemId!,
              versionId,
              teamId
            );
          } else {
            return testService.runWorkflowTest(
              testId,
              inputData.itemId!,
              versionId,
              teamId
            );
          }
        })
      );
      await Promise.all(testRunPromises);
      setRunTestState("success");
    } catch (e) {
      console.error("Error running tests:", e);
      setRunTestState("error");
    }
  }, [inputData, teamId, selectedTests, testService]);

  // Load team data
  useEffect(() => {
    if (!teamId) return;

    const loadTeam = async () => {
      const fetchedTeam = await teamService.teamRepo.get(
        teamService.teamPath(),
        teamId
      );
      setTeam(fetchedTeam ?? undefined);
    };

    loadTeam();
  }, [teamId, teamService]);

  const testCount = useMemo(() => {
    const versionCount = inputData?.versionIds?.length ?? 1;
    return versionCount * selectedTests.length;
  }, [inputData?.versionIds, selectedTests.length]);

  return (
    <CommonContainer>
      <CommonHeader
        title="Test Center"
        subtitle="Create and run tests for your portals. Catch the edge cases before your users do."
        sections={[
          { name: "Portals", link: `/teams/${teamId}/test-center` },
          { name: "Test Center", link: "" },
        ]}
        teamId={teamId!}
        actions={[
          <AnimatedButton
            key="runTestButton"
            buttonState={runTestState}
            setButtonState={setRunTestState}
            style="action"
            title={
              selectedTests.length === 0
                ? "Select tests"
                : `Run ${testCount} tests`
            }
            onClick={handleRunTest}
            disabled={selectedTests.length === 0}
          />,
          <AnimatedButton
            key="newTestButton"
            buttonState={newTestState}
            setButtonState={setNewTestState}
            style="secondary"
            title="New Test"
            onClick={handleNewTest}
            leftIcon="plus"
          />,
        ]}
      />

      <FailureModal
        shows={error !== ""}
        message={error}
        closed={() => setError("")}
      />

      {team && (
        <>
          <CommonWorkflowPortalSelector showTimespan={false} team={team} />
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <TestTable
                data={inputData}
                onSelectedTests={handleSelectedTests}
                team={team}
              />
              <TestRunTable team={team} data={inputData} />
            </div>
          </div>
        </>
      )}
    </CommonContainer>
  );
};
