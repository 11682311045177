export const AppPath = {
  login(): string {
    return "/login";
  },
  signup(): string {
    return "/signup";
  },
  onboarding(): string {
    return "/onboarding";
  },
  postOnboarding(): string {
    return "/postonboarding";
  },
  newTeam(): string {
    return `/newTeam`;
  },

  team(teamId: string): string {
    return `/teams/${teamId!}`;
  },

  portals(teamId: string): string {
    return `${this.team(teamId)}/portals`;
  },
  portal(teamId: string, portalId: string): string {
    return `${this.portals(teamId)}/${portalId}/version`;
  },
  portalVersion(
    teamId: string,
    portalId: string,
    versionId: string,
    editPortal?: boolean
  ): string {
    const baseUrl = `${this.portal(teamId, portalId)}/${versionId}`;
    const queryParam = editPortal ? "?showPortalModal=true" : "";
    return `${baseUrl}${queryParam}`;
  },

  logs(teamId: string): string {
    return `${this.team(teamId)}/logs`;
  },
  log(teamId: string, logId: string): string {
    return `${this.team(teamId)}/logs/${logId}`;
  },

  workflows(teamId: string): string {
    return `${this.team(teamId)}/workflows`;
  },
  workflow(
    teamId: string,
    workflowId: string,
    versionId?: string,
    editWorkflow?: boolean
  ): string {
    const baseUrl = `${this.workflows(teamId)}/${workflowId}`;
    const params = new URLSearchParams();
    if (editWorkflow) params.append("showNameModal", "true");
    if (versionId) params.append("versionId", versionId);
    const queryString = params.toString();
    return `${baseUrl}${queryString ? `?${queryString}` : ""}`;
  },

  settings(teamId: string): string {
    return `${this.team(teamId)}/settings`;
  },
  aiProviders(teamId: string, provider?: string): string {
    return `${this.settings(teamId)}/providers${
      provider ? `?provider=${provider}` : ""
    }`;
  },
  aiProviderDetail(teamId: string, provider: string): string {
    return `${this.settings(teamId)}/providers/${provider}`;
  },
  billing(teamId: string): string {
    return `${this.settings(teamId)}/billing`;
  },
  members(teamId: string): string {
    return `${this.settings(teamId)}/members`;
  },
  variables(teamId: string): string {
    return `${this.settings(teamId)}/variables`;
  },
  teamProfile(teamId: string): string {
    return `${this.settings(teamId)}/team-profile`;
  },
  tokens(teamId: string): string {
    return `${this.settings(teamId)}/tokens`;
  },
  testCenter(
    teamId: string,
    type?: "workflow" | "portal",
    itemId?: string,
    testId?: string,
    versions?: string[]
  ): string {
    let url = `${this.team(encodeURIComponent(teamId))}/test-center`;

    const params: string[] = [];

    if (type) {
      params.push(`type=${encodeURIComponent(type)}`);
    }

    if (itemId) {
      params.push(`id=${encodeURIComponent(itemId)}`);
    }

    if (testId) {
      params.push(`testId=${encodeURIComponent(testId)}`);
    }

    if (versions && versions.length > 0) {
      params.push(`versionIds=${versions.map(encodeURIComponent).join(",")}`);
    }

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return url;
  },
};
